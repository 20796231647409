import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class GdalService {

  constructor(private http: HttpClient) { };

  public getHelp(): Observable<any> {
    let temp = this.http.get(baseUrl + 'help');
    return temp;
  };

  public postFile(fileToUpload: File, type: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http.post(baseUrl + 'ogr2ogr/' + type, formData, { observe: 'response' as 'response', responseType: 'blob' as 'json' });
  };
}
